const api = {
  baseUrl: 'https://4j33anxucf.execute-api.eu-west-1.amazonaws.com/prod',
  signInUrl:
    'https://nestor-crypto-33.auth.eu-west-1.amazoncognito.com/login?client_id=4ti7p3v5bbvkrs8433feoe90l3&response_type=token&redirect_uri=https://nestor.pasalo.es',
  goToSignIn: () => {
    window.location.href = api.signInUrl
  },
  postData: async data => {
    return api.authFetch('/', 'POST', data)
  },
  getOperations: async () => {
    return api.authFetch('/', 'GET')
  },

  postSettings: async settings => {
    return api.authFetch('/settings', 'POST', settings)
  },
  getSettings: async () => {
    return api.authFetch('/settings', 'GET')
  },
  authFetch: async (path, method, bodyJson = null, headers = {}) => {
    const id_token = localStorage.id_token
    const url = api.baseUrl + path
    if (!id_token) {
      api.goToSignIn()
    }
    headers['Authorization'] = id_token
    finalHeaders = new Headers(headers)

    let options = {
      method,
      headers: finalHeaders
    }
    if (method == 'POST') {
      options.body = JSON.stringify(bodyJson)
    }

    try {
      let response = await fetch(url, options)
      console.log('response: ' + JSON.stringify(response))
      if (response.status == 401) {
        console.log('[401] Not authenticated')
        return api.goToSignIn()
      }
      return await response.json()
    } catch (error) {
      console.log('[error] ' + error)
      //return { message: 'error: ' + error }
      return api.goToSignIn()
    }
  }
}
module.exports = {
  api
}
