<template>
  <div>
    <div v-if="isLoading">
      <p>Fetching data. Almost there...</p>
      <v-progress-linear indeterminate></v-progress-linear>
    </div>

    <div v-else>
      <p>
        Random advice here, and not tested. Based on data from the last 7 days.
        Dip={{ data.params.dipFactor }}*current | High={{
          data.params.highFactor
        }}*current
      </p>
      <div
        v-for="(item, crypto) in data.data"
        :key="crypto"
        style="border: 1px solid gray; border-radius: 5px; padding: 5px; margin-top: 10px;"
      >
        <h2>{{ crypto }}: {{ item.current }}</h2>
        <span class="error--text" v-if="item.action == 1">
          Buy the dip?
        </span>
        <span class="success--text" v-if="item.action == -1">
          Rejoy!
        </span>
        <span v-else>HODL!</span>

        <br />
        <b>dip: </b> {{ item.dip }}
        <span style="font-size: 70%">
          (delta:
          {{
            (-1 *
              Math.round((10000 * (item.current - item.dip)) / item.current)) /
              100
          }}%)</span
        >
        <br />
        <b>high: </b>{{ item.high }}
        <span style="font-size: 70%">
          (delta:
          {{
            (-1 *
              Math.round((10000 * (item.current - item.high)) / item.current)) /
              100
          }}%)</span
        >
        <br />

        <v-chip
          x-small
          class="ml-1"
          v-for="(v, k) in item.calc"
          :key="k"
          :color="getColor(item, k)"
          ><b>{{ k.toUpperCase() }}</b
          >: {{ formatNumber(v) }}
        </v-chip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      key: 'value',
      isLoading: true,
      data: {},
      loading: false
    }
  },
  methods: {
    formatNumber(number) {
      var x = Math.round(number * 100) / 100
      return Intl.NumberFormat('en-US').format(x)
    },
    async fetchData() {
      this.isLoading = true
      const url =
        'https://63he4k82na.execute-api.eu-west-3.amazonaws.com/default/cryptobot2?noww=' +
        new Date()

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'holMhA92T25jq1laGTX0z8TkNnvrwRjB3A0lWrLm'
        }
      })
        .then(response => response.json())
        .then(data => {
          this.data = data

          this.isLoading = false
        })
    },
    getColor(item, k) {
      let current = item.current

      let minD = null
      let closestK = 0

      for (const k in item.calc) {
        const v = item.calc[k]

        if (Math.abs(current - v) < minD || minD == null) {
          minD = Math.abs(current - v)
          closestK = k
        }
      }

      let colors = {
        max: 'green accent-3',
        p99: 'green accent-2',
        p90: 'green accent-1',
        p10: 'red accent-1',
        p05: 'red accent-2',
        min: 'red accent-3'
      }

      if (k == closestK) {
        if (colors[k]) return colors[k]
        return 'accent'
      } else {
        return ''
      }
    }
  },

  mounted() {
    this.fetchData()
  }
}
</script>

<style lang="scss" scoped></style>
