<template>
  <div>
    <v-tabs v-model="tab" fixed-tabs class="mb-2">
      <v-tab>Export</v-tab>
      <v-tab>Import</v-tab>
    </v-tabs>

    <div v-if="tab == 0">
      <v-btn color="success" block @click="copyToClipboard()">{{
        copyText
      }}</v-btn>

      {{ message }}
      <br />

      <textarea
        class="codeTextArea"
        spellcheck="false"
        id="jsontext"
        cols="30"
        rows="5"
        :value="JSON.stringify(operations)"
      ></textarea>

      <br />
      Your data is saved on your browser, and only on the browser you are using.
      You may export your data as backup, or in case you switch browser and want
      to restore your current data.
      <br />
    </div>

    <div v-if="tab == 1">
      Paste your data here, and press restore.

      <textarea
        class="codeTextArea"
        spellcheck="false"
        id="jsontext2"
        cols="30"
        rows="5"
        v-model="importJsonString"
      ></textarea>
      <p>{{ message }}</p>
      <v-btn color="success" block @click="importData()">Restore</v-btn>
      <br />
      <v-btn color="error" block @click="reset()">Reset</v-btn>
    </div>

    <ad-sense-feed></ad-sense-feed>
  </div>
</template>

<script>
import AdSenseFeed from '../components/AdSenseFeed'
export default {
  components: {
    AdSenseFeed
  },
  props: {
    import: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      tab: 0,
      operations: [],
      message: '',
      copyText: 'Copy',
      importJsonString: []
    }
  },
  watch: {
    tab() {
      //remove message if tab changes
      this.message = ''
    }
  },
  methods: {
    reset() {
      this.importJsonString = '[]'
      this.importData()
    },
    importData() {
      try {
        let x = JSON.parse(this.importJsonString)
        let confirm = window.confirm(
          'Your current data with ' +
            this.operations.length +
            ' operations will be replaced with the new data (' +
            x.length +
            ' operations). Continue?'
        )
        if (confirm) {
          //localStorage.operations = this.importJsonString
          this.$store.dispatch(
            'updateOperations',
            JSON.parse(this.importJsonString)
          )
          this.operations = x
          this.message = 'Done! Data restored successfully'
        }
      } catch (error) {
        this.message =
          'Error. Invalid data (no JSON). Make sure you pasted your data in the box'
      }
    },
    copyToClipboard() {
      /* Get the text field */
      var copyText = document.getElementById('jsontext') //JSON.stringify(this.operations);

      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand('copy')

      /* Alert the copied text */
      //alert("Copied!");
      this.copyText = 'Copied!'
    }
  },
  created() {
    var xx = localStorage.operations
    if (xx) {
      this.operations = JSON.parse(xx)
    }
    if (this.import != '0') this.tab = 1
  }
}
</script>

<style lang="scss">
.codeTextArea {
  width: 100%;
  background-color: #f3f1f1;
  color: #6e0000;
  border: 2px solid #ccc !important;
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 10px;
  font-size: 10px;
  margin-top: 5px;
}
</style>
