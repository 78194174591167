<template>
  <div>
    <v-list three-line>
      <v-subheader>MY OPERATIONS ({{ operations.length }})</v-subheader>
      <v-list-item-group color="primary">
        <div v-for="(item, index) in operations" :key="index">
          <v-list-item :to="'/edit/' + item.id">
            <v-list-item-content>
              <v-list-item-title
                ><b>
                  {{ item.amount }} {{ item.currency }} at
                  {{ formatNumber(item.buy.price) }} |
                  {{
                    item.state == 'open'
                      ? 'Now at ' +
                        formatNumber(parseFloat(prices[item.currency]))
                      : 'Sold at ' + formatNumber(item.sell.price)
                  }}</b
                ></v-list-item-title
              >

              <!-- Sell-in -->
              <v-list-item-subtitle
                ><b>Sell-in: </b
                >{{ formatNumber(item.amount * item.buy.price) }}
                {{ settings.currency }} ({{
                  item.buy.date
                }})</v-list-item-subtitle
              >
              <!-- sell-out-->
              <v-list-item-subtitle>
                <b
                  >{{ item.state == 'open' ? 'If you sell now' : 'Sell-out' }}:
                </b>
                <span
                  :style="{
                    color:
                      (item.state == 'open'
                        ? parseFloat(prices[item.currency])
                        : parseFloat(item.sell.price)) >
                      parseFloat(item.buy.price)
                        ? 'green'
                        : 'red'
                  }"
                  >{{ selloutString(item) }}</span
                >
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="item.hide">
              <v-btn icon>
                <v-icon color="grey lighten-1">mdi-eye-off</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <ad-sense-feed v-if="(index + 1) % 5 == 0"></ad-sense-feed>
        </div>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import AdSenseFeed from './AdSenseFeed'
export default {
  components: {
    AdSenseFeed
  },
  props: {
    operations: {
      type: Array,
      default() {
        return []
      }
    },
    prices: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      settings: {}
    }
  },
  methods: {
    formatNumber(number) {
      var x = Math.round(number * 100) / 100
      return Intl.NumberFormat('en-US').format(x)
    },
    selloutString(item) {
      var selloutPrice =
        item.state == 'open' ? this.prices[item.currency] : item.sell.price
      var selloutValue = item.amount * selloutPrice

      return (
        this.formatNumber(selloutValue) +
        ' ' +
        this.settings.currency +
        ' | Delta: ' +
        this.formatNumber(item.amount * (selloutPrice - item.buy.price)) +
        ' (' +
        ((100 * (selloutPrice - item.buy.price)) / item.buy.price).toFixed(2) +
        '%)'
      )
    }
  },
  created() {
    var s = localStorage.nestor_settings
    if (!s) {
      this.$router.push('/settings')
    } else {
      this.settings = JSON.parse(s)
    }
  }
}
</script>

<style lang="scss" scoped></style>
