<template>
  <div>
    <h1>Settings</h1>

    <v-text-field
      name="username"
      label="username"
      type="username"
      v-model="settings.username"
      hint="This is useless for now. You can put it or not."
    ></v-text-field>

    <v-text-field
      name="Currency"
      label="Your base currency *"
      id="id"
      v-model="settings.currency"
      hint="Valid currencies are EUR, USD, etc."
    ></v-text-field>
    <p>
      <b>Note:</b> This app only accepts one base currency for all your
      operations, so it will assume you always use this one to buy/sell crypto
      currencies. No conversions are done at all.
    </p>

    <v-row>
      <v-col>
        <v-btn color="error" @click="cancel()" block>Cancel</v-btn>
      </v-col>
      <v-col>
        <v-btn color="success" @click="save()" block>Save</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      default_settings: { currency: 'EUR', username: '' },
      settings: {}
    }
  },
  methods: {
    cancel() {
      this.$router.push('/')
    },
    save() {
      localStorage.nestor_settings = JSON.stringify(this.settings)
      this.$router.push('/')
    }
  },
  created() {
    var xx = localStorage.nestor_settings
    if (xx) {
      this.settings = JSON.parse(xx)
    } else {
      this.settings = this.default_settings
      localStorage.nestor_settings = JSON.stringify(this.default_settings) //make sure we save something.
    }
  }
}
</script>

<style lang="scss" scoped></style>
