<template>
  <div>
    <p>
      This tool has been moved. To keep using it, please copy your data and
      paste it in the new location of the tool. Then remember to save the new
      link to access to it.
    </p>

    <v-btn color="success" block depressed @click="copyToClipboard()">{{
      copyText
    }}</v-btn>

    <br />

    <textarea
      class="codeTextArea"
      spellcheck="false"
      id="jsontext"
      cols="30"
      rows="5"
      :value="JSON.stringify(operations)"
    ></textarea>
    <p>Your have data with {{ operations.length }} operations.</p>

    <v-btn color="primary" block depressed @click="goToNewLocation()"
      >Go to new location</v-btn
    >

    <br />
    <b>Note:</b> Your data is saved on your browser, and only on the browser you
    are using. You can use the "export/import" feature in the new version, to
    save backups of your data, or use it for your own automations.
    <br />
  </div>
</template>

<script>
export default {
  data() {
    return {
      operations: [],
      copyText: 'Copy',
      importJsonString: []
    }
  },
  methods: {
    goToNewLocation() {
      let newUrl = 'https://pasalo.es/apps/crypto/#/export/1'
      window.location.replace(newUrl)
    },
    copyToClipboard() {
      /* Get the text field */
      var copyText = document.getElementById('jsontext') //JSON.stringify(this.operations);

      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand('copy')

      /* Alert the copied text */
      //alert("Copied!");
      this.copyText = 'Copied!'
    }
  },
  created() {
    var xx = localStorage.operations
    if (xx) {
      this.operations = JSON.parse(xx)
    }
  }
}
</script>

<style lang="scss">
.codeTextArea {
  width: 100%;
  background-color: #f3f1f1;
  color: #6e0000;
  border: 2px solid #ccc !important;
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 10px;
  font-size: 10px;
  margin-top: 5px;
}
</style>
