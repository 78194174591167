<template>
  <div class="home">
    Hello {{ apiData.email }}
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="yellow darken-2"
    ></v-progress-linear>
    <v-tabs v-model="tabs1" fixed-tabs>
      <v-tab>Open</v-tab>
      <v-tab>Closed</v-tab>
      <v-tab>All</v-tab>
    </v-tabs>

    <v-text-field
      name="Search"
      :hint="tabList.length + ' results'"
      label="Search"
      v-model="searchText"
      @focus="$event.target.select()"
      prepend-inner-icon="mdi-magnify"
      clearable
    ></v-text-field>

    <!-- summary -->

    <div>
      <summary-card :item="totalSummary"></summary-card>
    </div>

    <v-tabs v-model="tabs2" fixed-tabs>
      <v-tab>Totals</v-tab>
      <v-tab>Operations</v-tab>
    </v-tabs>

    <operations-list
      :operations="tabList"
      :prices="prices"
      v-if="tabs2 == 1"
    ></operations-list>

    <div v-if="tabs2 == 0">
      <!-- If user clicks on the card, we move to operations tab, and add the currency to the searchbox, to filter those :)-->
      <div
        v-for="(v, k) in recapPerCurrency"
        :key="k"
        @click="goToTab(1, currencyCode)"
      >
        <summary-card :item="v" :prices="prices"></summary-card>

        <!-- Add and ad every 3 items, starting after the second -->
        <div v-if="(Object.keys(recapPerCurrency).indexOf(k) + 2) % 3 == 0">
          <ad-sense-feed></ad-sense-feed>
        </div>
      </div>
      <!-- Add at the end (might happend there are two in a row, but ok for now) -->
      <!--<ad-sense-feed></ad-sense-feed>-->
    </div>

    <v-btn
      color="success"
      fab
      large
      bottom
      right
      fixed
      dark
      @click="addOperation()"
      class="mb-10"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
// @ is an alias to /src

var sharedjs = require('../sharedjs')
const api = require('../js/api').api
import { mutations } from '../vuex/types'
import OperationsList from '../components/OperationsList'
import SummaryCard from '../components/SummaryCard'
import AdSenseFeed from '../components/AdSenseFeed'

export default {
  name: 'Home',
  components: {
    OperationsList,
    SummaryCard,
    AdSenseFeed
  },
  data() {
    return {
      apiData: {},
      settings: {},
      isLoading: false,
      tabs1: 0,
      tabs2: 0,
      searchText: '',
      currencies: ['BCH', 'BTC', 'ETH', 'XLM'],
      prices: {},
      emptyOperation: {
        id: '',
        amount: null,
        buy: {
          date: null,
          price: null
        },
        currency: '',
        owner: '',
        sell: {
          date: null,
          price: null
        },
        state: 'open',
        hide: false
      },
      operations: []
    }
  },
  watch: {
    tabs2(newValue) {
      if (newValue == 0 && this.searchText in this.prices) {
        this.searchText = ''
        window.scroll(0, 0)
      }
    },
    operations(newValue) {
      localStorage.operations = JSON.stringify(newValue)
    }
  },
  methods: {
    goToTab(tabNumber, currencyCode) {
      this.tabs2 = tabNumber
      searchText = searchText ? searchText + ' ' + currencyCode : currencyCode
    },
    async fetchOperations() {
      //this.operations = await api.getOperations()
      console.log('aaa33')
      this.apiData = await api.getOperations()
      this.operations = this.apiData.operations
      this.$store.commit(mutations.SET_OPERATIONS, this.operations)
      console.log('xxx33', this.apiData)
    },
    selloutString(item) {
      var selloutPrice =
        item.state == 'open' ? this.prices[item.currency] : item.sell.price
      var selloutValue = (item.amount * selloutPrice).toFixed(2)

      return (
        selloutValue +
        ' ' +
        this.settings.currency +
        ' | delta: ' +
        (item.amount * (selloutPrice - item.buy.price)).toFixed(2) +
        ' (' +
        ((100 * (selloutPrice - item.buy.price)) / item.buy.price).toFixed(2) +
        '%)'
      )
    },
    addOperation() {
      var d = new Date()
      var n = d.getTime()
      var xx = { ...this.emptyOperation }
      xx.id = n
      this.operations.unshift(xx)
      localStorage.operations = JSON.stringify(this.operations)
      this.$router.push('/edit/' + n)
    },
    fetchCurrenciesValue() {
      this.isLoading = true
      var url =
        'https://api.coinbase.com/v2/exchange-rates?currency=' +
        this.settings.currency

      fetch(url)
        .then(response => response.json())
        .then(data => {
          var x1 = data.data.rates
          var x2 = {}

          Object.keys(x1).forEach(currency => {
            x2[currency] = parseFloat(1 / x1[currency])
          })
          this.prices = x2
          this.isLoading = false
        })
    }
  },
  computed: {
    recapPerCurrency() {
      var xx = {}
      /*var xx = {
        total: {
          currency: 'total',
          amount: 0,
          sellin: 0,
          sellout: 0,
          delta: 0,
          perc: 0
        }
      }*/

      this.tabList.forEach(op => {
        if (!(op.currency in xx)) {
          xx[op.currency] = {
            currency: op.currency,
            sellin: 0,
            sellout: 0,
            amount: 0
          }
        }

        var p0 = op.buy.price
        var pf = op.state == 'open' ? this.prices[op.currency] : op.sell.price
        var a = parseFloat(op.amount)
        if (!op.hide) {
          /*xx.total.amount = xx.total.amount + a
          xx.total.sellin = xx.total.sellin + a * p0
          xx.total.sellout = xx.total.sellout + a * pf*/

          xx[op.currency].amount = xx[op.currency].amount + a
          xx[op.currency].sellin = xx[op.currency].sellin + a * p0
          xx[op.currency].sellout = xx[op.currency].sellout + a * pf
        }
      })

      Object.keys(xx).forEach(c => {
        var d = xx[c].sellout - xx[c].sellin
        xx[c].delta = d.toFixed(2)
        xx[c].delta = xx[c].delta >= 0 ? '+' + xx[c].delta : xx[c].delta

        xx[c].perc = ((100 * d) / xx[c].sellin).toFixed(2) + '%'
        xx[c].sellin = xx[c].sellin.toFixed(2)
        xx[c].sellout = xx[c].sellout.toFixed(2)
      })

      return xx
    },
    totalSummary() {
      var xx = {
        currency: 'total',
        sellin: 0,
        sellout: 0,
        delta: 0,
        perc: 0
      }

      this.tabList.forEach(op => {
        var p0 = op.buy.price
        var pf = op.state == 'open' ? this.prices[op.currency] : op.sell.price
        var a = parseFloat(op.amount)

        if (!op.hide) {
          xx.sellin = xx.sellin + a * p0
          xx.sellout = xx.sellout + a * pf
        }
      })

      xx.delta = xx.sellout - xx.sellin
      xx.perc = (xx.sellout - xx.sellin) / xx.sellin

      xx.sellin = xx.sellin.toFixed(2)
      xx.sellout = xx.sellout.toFixed(2)
      xx.delta = xx.delta.toFixed(2)
      xx.perc = (100 * xx.perc).toFixed(2) + '%'

      return xx
    },
    stateFocus() {
      return this.tabs1 == 0 ? 'open' : this.tabs1 == 1 ? 'closed' : 'all'
    },
    tabList() {
      var xx = []

      this.searchFilteredList.forEach(op => {
        if (this.stateFocus == op.state || this.stateFocus == 'all') {
          xx.push(op)
        }
      })
      return xx
    },
    searchFilteredList() {
      //get the item comment, if any

      //do the search and add if match
      var output = []
      if (this.operations.length) {
        this.operations.forEach(item => {
          var commentt = ''
          try {
            commentt = item.comments //might not have comments!
          } catch (error) {
            console.log('e21: ' + error)
          }

          var xx =
            item.currency +
            ' ' +
            item.state +
            ' ' +
            item.owner +
            ' ' +
            item.buy.date +
            ' ' +
            commentt

          //TODO: use search keywords as array probably, if so, just join them :)
          if (sharedjs.searchMatch(this.searchText, xx)) {
            output.push(item)
          }
        })
      }

      //sort most recent changed first
      output = output.sort(
        (a, b) =>
          new Date(
            b.sell.date != null
              ? b.sell.date
              : b.buy.date != null
              ? b.buy.date
              : 9999999999999999999999
          ) - new Date(a.sell.date != null ? a.sell.date : a.buy.date)
      )
      return output
    }
  },
  created() {
    var s = localStorage.nestor_settings
    if (!s) {
      this.$router.push('/settings')
    } else {
      this.settings = JSON.parse(s)
    }
    this.fetchCurrenciesValue()
    /*var xx = localStorage.operations
    if (xx) {
      this.operations = JSON.parse(xx)
    }*/
    this.fetchOperations()
  }
}
</script>
