var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-list',{attrs:{"three-line":""}},[_c('v-subheader',[_vm._v("MY OPERATIONS ("+_vm._s(_vm.operations.length)+")")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.operations),function(item,index){return _c('div',{key:index},[_c('v-list-item',{attrs:{"to":'/edit/' + item.id}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('b',[_vm._v(" "+_vm._s(item.amount)+" "+_vm._s(item.currency)+" at "+_vm._s(_vm.formatNumber(item.buy.price))+" | "+_vm._s(item.state == 'open' ? 'Now at ' + _vm.formatNumber(parseFloat(_vm.prices[item.currency])) : 'Sold at ' + _vm.formatNumber(item.sell.price)))])]),_c('v-list-item-subtitle',[_c('b',[_vm._v("Sell-in: ")]),_vm._v(_vm._s(_vm.formatNumber(item.amount * item.buy.price))+" "+_vm._s(_vm.settings.currency)+" ("+_vm._s(item.buy.date)+")")]),_c('v-list-item-subtitle',[_c('b',[_vm._v(_vm._s(item.state == 'open' ? 'If you sell now' : 'Sell-out')+": ")]),_c('span',{style:({
                  color:
                    (item.state == 'open'
                      ? parseFloat(_vm.prices[item.currency])
                      : parseFloat(item.sell.price)) >
                    parseFloat(item.buy.price)
                      ? 'green'
                      : 'red'
                })},[_vm._v(_vm._s(_vm.selloutString(item)))])])],1),(item.hide)?_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-eye-off")])],1)],1):_vm._e()],1),((index + 1) % 5 == 0)?_c('ad-sense-feed'):_vm._e()],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }