<template>
  <div>
    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="dateAux"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-combobox
          :label="label"
          v-model="dateAux"
          prepend-icon="mdi-calendar-month"
          readonly
          v-on="on"
        ></v-combobox>
      </template>
      <v-date-picker
        v-model="dateAux"
        locale="es-ES"
        first-day-of-week="1"
        scrollable
        :multiple="multiple"
      >
        <v-btn text color="success" @click="dateAux = formatDate(new Date())"
          >Today</v-btn
        >
        <div class="flex-grow-1"></div>
        <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
        <v-btn text color="primary" @click="$refs.dialog.save(dateAux)"
          >OK</v-btn
        >
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false
    }
  },
  props: {
    value: {
      required: true,
      default: function() {
        return ''
      }
    },
    label: {
      type: String,
      required: false,
      default: 'Fecha'
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('-')
    }
  },
  computed: {
    dateAux: {
      get: function() {
        return this.value
      },
      set: function(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
