<template>
  <div>
    <div :id="adId" style="margin:auto"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      kaixo: '',
      adId: 'id-will-be-created-dynamically-on-create'
    }
  },
  methods: {
    injectAd() {
      var externalScript = document.createElement('script')
      externalScript.type = 'text/javascript'
      externalScript.setAttribute('async', 'async')
      externalScript.src =
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
      document.getElementById(this.adId).appendChild(externalScript)

      var ins = document.createElement('ins')
      ins.setAttribute('class', 'adsbygoogle')
      ins.setAttribute('style', 'display:block;')
      ins.setAttribute('data-ad-client', 'ca-pub-8825790157435307')
      ins.setAttribute('data-ad-slot', '6412487577')
      ins.setAttribute('data-ad-format', 'fluid')
      ins.setAttribute('data-ad-layout-key', '-ex+5o+5o-ct+35')

      document.getElementById(this.adId).appendChild(ins)

      var inlineScript = document.createElement('script')
      inlineScript.type = 'text/javascript'
      inlineScript.text = '(adsbygoogle = window.adsbygoogle || []).push({});'
      document.getElementById(this.adId).appendChild(inlineScript)
    }
  },
  created() {
    //1. Create a unique Id for this ad (//TODO: there are better ways of doing this...)
    var d = new Date()
    var n = d.getTime()
    this.adId = n + Math.random() //just in case :);
  },

  mounted() {
    //Add the Google code!
    this.injectAd()
  }
}
</script>

<style lang="scss" scoped></style>
