<template>
  <v-app style="max-width: 500px ; margin: auto">
    <v-navigation-drawer temporary app v-model="showMenu">
      <NavMenu />
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click="showMenu = !showMenu"></v-app-bar-nav-icon>
      <v-toolbar-title
        class="headline text-uppercase"
        @click="window.location.reload()"
      >
        <span>Crypto Wins Tracker</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavMenu from './components/NavMenu'
export default {
  name: 'App',
  components: {
    NavMenu
  },
  data() {
    return {
      showMenu: false
    }
  },
  created() {
    let url = window.location.href
    if (url.includes('access_token')) {
      console.log('tokens found in url')
      const access_token = url.split('access_token=')[1].split('&')[0]
      const id_token = url.split('id_token=')[1].split('&')[0]
      console.log('access_token', access_token)
      console.log('id_token', id_token)
      localStorage.setItem('access_token', access_token)
      localStorage.setItem('id_token', id_token)
      window.location.href = '/'
    } else {
      console.log('acces_token not found')
    }
  }
}
</script>
