<template>
  <div>
    <v-text-field
      name="Search"
      :hint="filteredList.length + ' results'"
      label="Search"
      v-model="searchText"
      @focus="$event.target.select()"
      prepend-inner-icon="mdi-magnify"
      clearable
    ></v-text-field>

    <v-list>
      <v-subheader>Prices</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item v-for="(item, index) in filteredList" :key="index">
          <v-list-item-content>
            <v-list-item-title
              ><b>{{ item.currency }}</b
              >: {{ item.value }} {{ settings.currency }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <ad-sense-feed></ad-sense-feed>
  </div>
</template>

<script>
import AdSenseFeed from '../components/AdSenseFeed'
var sharedjs = require('../sharedjs')
export default {
  components: {
    AdSenseFeed
  },
  data() {
    return {
      settings: {},
      searchText: '',
      prices: {}
    }
  },
  computed: {
    filteredList() {
      var output = []
      Object.keys(this.prices).forEach(currency => {
        var xx = currency
        //TODO: use search keywords as array probably, if so, just join them :)
        if (sharedjs.searchMatch(this.searchText, xx)) {
          output.push({ currency, value: this.prices[currency] })
        }
      })
      return output
    }
  },
  methods: {
    fetchCurrenciesValue() {
      var url =
        'https://api.coinbase.com/v2/exchange-rates?currency=' +
        this.settings.currency

      fetch(url)
        .then(response => response.json())
        .then(data => {
          var x1 = data.data.rates
          var x2 = {}

          Object.keys(x1).forEach(currency => {
            x2[currency] = (1 / x1[currency]).toFixed(2)
          })
          this.prices = x2
        })
    }
  },
  created() {
    var s = localStorage.nestor_settings
    if (!s) {
      this.$router.push('/settings')
    } else {
      this.settings = JSON.parse(s)
    }

    this.fetchCurrenciesValue()
    var xx = localStorage.operations
    if (xx) {
      this.operations = JSON.parse(xx)
    }
  }
}
</script>

<style lang="scss" scoped></style>
