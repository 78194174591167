import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Edit from '../views/Edit.vue'
import ExportJson from '../views/ExportJson.vue'
import Prices from '../views/Prices.vue'
import PerCurrency from '../views/PerCurrency.vue'
import Settings from '../views/Settings.vue'
import Migrate from '../views/Migrate.vue'
import Action from '../views/Action.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/edit/:id',
    name: 'Edit',
    component: Edit,
    props: true
  },
  {
    path: '/prices',
    name: 'Prices',
    component: Prices
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/export/:import?',
    name: 'ExportJson',
    component: ExportJson,
    props: true
  },
  {
    path: '/migrate',
    name: 'Migrate',
    component: Migrate
  },
  {
    path: '/action',
    name: 'Action',
    component: Action
  },
  {
    path: '/per-currency',
    name: 'PerCurrency',
    component: PerCurrency
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes,
  //mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

//below code is using AWS Amplify Auth, but you could use another rule if useful.
router.beforeEach(async (to, from, next) => {
  console.log('to', to)

  let url = String(window.location)
  if (url.includes('www.')) {
    window.location.replace(url.replace('www.', ''))
  }

  //TODO: if pasalo.es/apps/crypto, we should ask user to migrate to nestor.pasalo.es

  let isPasalo = url.includes('pasalo')
  if (!isPasalo) isPasalo = url.includes('localhost')
  const requiresPasalo = to.matched.some(record => record.path != '/migrate') //only /migrate route should work outside of pasalo

  console.log('isPasalo', isPasalo)
  console.log('requiresPasalo', requiresPasalo)

  if (requiresPasalo && !isPasalo) {
    next('/migrate')
  } else {
    next()
  }
})

export default router
