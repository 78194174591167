<template>
  <div style="padding-top: 5px; padding-bottom: 5px">
    <v-card class="mx-auto">
      <v-card-text>
        <div>{{ item.currency.toUpperCase() }}</div>
        <p
          class="display-1 "
          :style="{
            color: item.delta > 0 ? 'green' : 'red'
          }"
        >
          {{ item.delta }} {{ settings.currency }}
          <span style="font-size: 60%; ">({{ item.perc }})</span>
        </p>

        <p style="margin-bottom:0px">
          Sellin: <b>{{ formatNumber(item.sellin) }}</b> | Sellout:
          <b>{{ formatNumber(item.sellout) }}</b>
        </p>
        <p v-if="item.currency != 'total'">
          Amount:
          <b> {{ Math.round(item.amount * 10000) / 10000 }}</b> | Current:
          <b>{{ formatNumber(prices[item.currency]) }}</b> | Avg Buy:
          {{ formatNumber(item.sellin / item.amount) }}
        </p>

        <div style="position:absolute; width: 45%;  right:5px; top: 70px;">
          <!--<h2>points: {{ history.length }}</h2>-->
          <v-sparkline
            :value="history"
            :gradient="['#018EE3']"
            :smooth="10 || false"
            :padding="8"
            :line-width="2"
            stroke-linecap="round"
            gradient-direction="top"
            :fill="false"
            type="trend"
            :auto-line-width="false"
            auto-draw
          ></v-sparkline>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return { sellin, sellout, delta, perc, amount }
      }
    },
    prices: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      settings: {},
      fetchTries: 0,
      history: []
    }
  },
  methods: {
    formatNumber(number) {
      var x = Math.round(number * 100) / 100
      return Intl.NumberFormat('en-US').format(x)
    },
    fetchHistory(currency) {
      console.log('starting fetch for ' + currency)
      //https://stackoverflow.com/questions/51417285/coinbase-api-v2-getting-historic-price-for-multiple-days
      //https://docs.pro.coinbase.com/#get-historic-rates
      var granularity_seconds = 3600 //secs  note: max num of points returned is 300
      var days_back = 8
      var auxx = new Date()

      var stop = auxx //'2021-02-08T12:00:00'
      console.log('stop date: ' + stop.toISOString())

      auxx.setDate(auxx.getDate() - days_back)
      var start = auxx //2021-02-02T12:00:00'
      console.log('start date: ' + start.toISOString())

      var url =
        'https://api.pro.coinbase.com/products/' +
        currency +
        '-' +
        this.settings.currency +
        '/candles?start=' +
        start.toISOString() +
        '&stop=' +
        stop.toISOString() +
        '&granularity=' +
        granularity_seconds

      fetch(url)
        .then(response => response.json())
        .then(data => {
          if (Array.isArray(data)) {
            data.sort((a, b) => a[0] - b[0]) //sort by date, since Coinbase appends extra points to fill the 300 points.

            console.log('history', data)
            data.forEach(d => {
              var datee = new Date(d[0] * 1000)

              if (datee >= start) {
                this.history.push(d[1]) //1: low, 2: high, 3: open, 4: close
              }
            })
          } else if (
            data.message == 'Public rate limit exceeded' &&
            this.fetchTries < 4
          ) {
            //let's try again if rate was exceed
            this.fetchTries++
            setTimeout(() => {
              this.fetchHistory(this.item.currency)
            }, this.fetchTries * 1000)
          }
        })
    }
  },
  created() {
    var s = localStorage.nestor_settings
    if (!s) {
      this.$router.push('/settings')
    } else {
      this.settings = JSON.parse(s)
    }

    this.fetchHistory(this.item.currency)
  }
}
</script>

<style lang="scss" scoped></style>
