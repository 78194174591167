<template>
  <div>
    <v-list>
      <v-subheader>OUR PAGES</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(item, index) in pages"
          :key="index"
          :to="item.path"
          dense
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ item.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-container grid-list-xs>
      <v-switch label="Dark Mode" v-model="darkMode"></v-switch>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      darkMode: false,
      pages: [
        {
          title: 'Home',
          path: '/',
          icon: 'mdi-home',
          description: 'Home'
        },
        {
          title: 'Action',
          path: '/action',
          icon: 'mdi-help-circle',
          description: 'What should I do?'
        },
        {
          title: 'Export/Import Data',
          path: '/export',
          icon: 'mdi-code-json',
          description: 'Copy paste for now'
        },

        {
          title: 'Prices',
          path: '/prices',
          icon: 'mdi-currency-btc',
          description: 'Check prices of all currencies'
        },

        {
          title: 'Settings',
          path: '/settings',
          icon: 'mdi-cog',
          description: 'Settings'
        }

        /*
        {
          title: 'PerCurrency',
          path: '/per-currency',
          icon: 'mdi-trending-up',
          description: 'Check how our you doing per currency'
        }
        */
      ]
    }
  },
  watch: {
    darkMode(newValue, oldValue) {
      if (newValue == true) {
        this.$vuetify.theme.dark = true
        sessionStorage.darkMode = true
      } else {
        this.$vuetify.theme.dark = false
        sessionStorage.darkMode = false
      }
    }
  },
  methods: {
    darkModeToggle() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
    countType(type) {
      var xx = 0
      this.people.forEach(p => {
        if (p.type == type) {
          xx++
        }
      })
      return xx
    }
  },
  created() {
    this.darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches

    if (sessionStorage.darkMode) {
      this.darkMode = true
    }
  }
}
</script>

<style lang="scss" scoped></style>
