<template>
  <div>
    <h2>Wins/Losts per currency</h2>
  </div>
</template>

<script>
export default {
  data() {
    return {
      key: 'value'
    }
  }
}
</script>

<style lang="scss" scoped></style>
