import Vue from 'vue'
import Vuex from 'vuex'

import { mutations } from './types'

import { api } from '../js/api'

//Old NOTE: we are setting the first values of the store in App.vue

Vue.use(Vuex)

function checkSettings(settings) {
  if (settings) {
    // Force default values if any key has a different data type or is missing (wonderful in case we add further settings)
    for (var key in store.state.default_settings) {
      if (typeof store.state.default_settings[key] !== typeof settings[key]) {
        settings[key] = store.state.default_settings[key]
      }
    }
    return settings
  } else {
    console.error('[alex] ouch', settings)
    return store.state.default_settings
  }
}

const store = new Vuex.Store({
  state: {
    settings: {},
    default_settings: {
      currencyCode: 'EUR'
    },
    operations: []
  },
  getters: {
    //getters are like computed properties for the store. Use store.getters.testPlus1 to access
    settings2: state => {
      return state.settings
    }
  },
  mutations: {
    //trigger mutations by using store.commit(mutationName, payload)

    [mutations.SET_SETTINGS](state, settings) {
      //0. validate settings structure, use default where not right
      settings = checkSettings(settings)

      //1. Save locally as fallback (in case no extension or removed later)
      //localStorage.settings = JSON.stringify(settings)

      //2. Save in store (where it is really used)
      state.settings = { ...settings }
    },
    [mutations.SET_OPERATIONS](state, operations) {
      localStorage.operations = JSON.stringify(operations)
      state.operations = operations
    }
  },
  actions: {
    async updateSettings({ commit }, settings) {
      console.log('[alex] action: updateSettings', settings)

      settings = checkSettings(settings)

      console.log('[alex] action: cleaned settings: ', settings)

      //1. Update web
      commit(mutations.SET_SETTINGS, settings)

      //2. Update backend
      await api.postSettings(settings)
    },
    async updateOperations({ commit }, data) {
      console.log('[alex] action: updateData', data)
      commit(mutations.SET_OPERATIONS, data)

      await api.postData(data)
    }
  }

  //we can also use ACTIONS, if we want to do something async (like fetching data from API)
})

export default store
