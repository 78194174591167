<template>
  <div>
    <v-row>
      <v-col cols="8">
        <h2>ID: {{ this.id }}</h2>
      </v-col>
      <v-col cols="4" style="text-align:right">
        <v-btn color="error" icon @click="remove()"
          ><v-icon color="red">mdi-delete</v-icon></v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <v-text-field
          name="amount"
          label="Amount of crypto"
          v-model="operation.amount"
          type="text"
          pattern="[\d\.]*"
          inputmode="decimal"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="operation.currency"
          :items="Object.keys(prices)"
          :loading="isLoading"
          color="white"
          item-text="Currencye"
          label="Currency"
          placeholder="Start typing to search"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <h3>Buy</h3>

    <v-row>
      <v-col cols="6">
        <v-text-field
          name="buy price"
          :label="'Crypto buy price in ' + settings.currency"
          v-model="operation.buy.price"
          type="text"
          pattern="[\d\.]*"
          inputmode="decimal"
          :hint="
            'It costed you ' +
              (operation.buy.price * operation.amount).toFixed(3) +
              ' ' +
              settings.currency
          "
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <my-date-picker
          v-model="operation.buy.date"
          label="Buy Date"
        ></my-date-picker>
      </v-col>
    </v-row>

    <h3>Sell</h3>

    <v-row>
      <v-col cols="6">
        <v-text-field
          name="sell price"
          :label="'Crypto sale price in ' + settings.currency"
          v-model="operation.sell.price"
          type="text"
          pattern="[\d\.]*"
          inputmode="decimal"
          :hint="
            (operation.sell.price * operation.amount).toFixed(3) +
              ' ' +
              settings.currency +
              ' (Delta: ' +
              (
                operation.amount *
                (operation.sell.price - operation.buy.price)
              ).toFixed(2) +
              ')'
          "
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <my-date-picker
          v-model="operation.sell.date"
          label="Sell Date"
        ></my-date-picker>
      </v-col>
    </v-row>

    <v-select
      :items="['open', 'closed']"
      v-model="operation.state"
      label="state"
      persistent-hint
      :hint="
        operation.state == 'closed'
          ? 'Delta: ' +
            (
              operation.amount *
              (operation.sell.price - operation.buy.price)
            ).toFixed(2) +
            ' ' +
            settings.currency
          : ''
      "
    ></v-select>

    <v-switch
      v-model="operation.hide"
      label="Hide from calculations / totals"
    ></v-switch>

    <v-text-field
      name="Comments"
      label="Comments"
      v-model="operation.comments"
    ></v-text-field>

    <v-row>
      <v-col>
        <v-btn color="error" @click="cancel()" block>Cancel</v-btn>
      </v-col>
      <v-col>
        <v-btn color="success" @click="save()" block>Save</v-btn>
      </v-col>
    </v-row>

    <br />

    <span hidden>{{ operation }}</span>
    <br />
    <ad-sense-feed></ad-sense-feed>
  </div>
</template>

<script>
import { api } from '../js/api.js'
import MyDatePicker from '../components/MyDatePicker'
import AdSenseFeed from './../components/AdSenseFeed'

export default {
  components: {
    MyDatePicker,
    AdSenseFeed
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      amountAux: 0,
      settings: {},
      prices: [],
      isLoading: false,
      operation: {
        id: '',
        amount: null,
        buy: {
          date: null,
          price: null
        },
        currency: '',
        owner: '',
        sell: {
          date: null,
          price: null
        },
        state: 'open'
      }
    }
  },

  watch: {
    operation: {
      deep: true,
      handler: function(newValue, oldValue) {
        this.operation.amount = newValue.amount?.replace(',', '.')
        this.operation.buy.price = newValue.buy.price?.replace(',', '.')
        this.operation.sell.price = newValue.sell.price?.replace(',', '.')
      }
    },
    amountAux(newValue, oldValue) {
      this.operation.amount = +newValue.replace(',', '.')
    }
  },

  methods: {
    getToday() {
      var d = new Date()
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('-')
    },
    async save() {
      if (this.operation.state != 'closed' && this.operation.sell.price > 0) {
        var conf = confirm("Hey! Do you want to mark this as 'closed today'?")
        if (conf) {
          this.operation.state = 'closed'
          this.operation.sell.date = this.getToday()
        } else {
          conf = confirm('You put a sell number... you sure you are done?')
          if (!conf) {
            return
          }
        }
      }

      //var xx = JSON.parse(localStorage.operations)
      var xx = this.$store.state.operations

      for (let index = 0; index < xx.length; index++) {
        const element = xx[index]
        if (element.id == this.id) {
          xx[index] = this.operation
        }
      }
      //localStorage.operations = JSON.stringify(xx)

      await this.$store.dispatch('updateOperations', xx)

      this.$router.push('/')
    },
    cancel() {
      this.$router.push('/')
    },
    async remove() {
      if (!confirm('You sure?')) {
        return
      }
      var xx = JSON.parse(localStorage.operations)

      for (let index = 0; index < xx.length; index++) {
        const element = xx[index]
        if (element.id == this.id) {
          xx.splice(index, 1)
        }
      }
      //localStorage.operations = JSON.stringify(xx)
      await this.$store.dispatch('updateOperations', xx)
      this.$router.push('/')
    },
    fetchCurrenciesValue() {
      this.isLoading = true
      var url =
        'https://api.coinbase.com/v2/exchange-rates?currency=' +
        this.settings.currency

      fetch(url)
        .then(response => response.json())
        .then(data => {
          var x1 = data.data.rates
          var x2 = {}

          Object.keys(x1).forEach(currency => {
            x2[currency] = (1 / x1[currency]).toFixed(2)
          })
          this.prices = x2
          this.isLoading = false
        })
    }
  },

  mounted() {
    //settings
    var s = localStorage.nestor_settings
    if (!s) {
      this.$router.push('/settings')
    } else {
      this.settings = JSON.parse(s)
    }

    //rest
    this.fetchCurrenciesValue()
    var xx = JSON.parse(localStorage.operations)
    if (xx) {
      xx.forEach(op => {
        if (String(op.id) == this.id) {
          this.operation = op
        }
      })
    }
    if (
      this.operation.amount == null &&
      this.operation.currency == '' &&
      this.operation.buy.date == null
    ) {
      this.operation.buy.date = this.getToday()
    }
  }
}
</script>

<style lang="scss" scoped></style>
