var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('h2',[_vm._v("ID: "+_vm._s(this.id))])]),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.remove()}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"name":"amount","label":"Amount of crypto","type":"text","pattern":"[\\d\\.]*","inputmode":"decimal"},model:{value:(_vm.operation.amount),callback:function ($$v) {_vm.$set(_vm.operation, "amount", $$v)},expression:"operation.amount"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":Object.keys(_vm.prices),"loading":_vm.isLoading,"color":"white","item-text":"Currencye","label":"Currency","placeholder":"Start typing to search"},model:{value:(_vm.operation.currency),callback:function ($$v) {_vm.$set(_vm.operation, "currency", $$v)},expression:"operation.currency"}})],1)],1),_c('h3',[_vm._v("Buy")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"name":"buy price","label":'Crypto buy price in ' + _vm.settings.currency,"type":"text","pattern":"[\\d\\.]*","inputmode":"decimal","hint":'It costed you ' +
            (_vm.operation.buy.price * _vm.operation.amount).toFixed(3) +
            ' ' +
            _vm.settings.currency},model:{value:(_vm.operation.buy.price),callback:function ($$v) {_vm.$set(_vm.operation.buy, "price", $$v)},expression:"operation.buy.price"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('my-date-picker',{attrs:{"label":"Buy Date"},model:{value:(_vm.operation.buy.date),callback:function ($$v) {_vm.$set(_vm.operation.buy, "date", $$v)},expression:"operation.buy.date"}})],1)],1),_c('h3',[_vm._v("Sell")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"name":"sell price","label":'Crypto sale price in ' + _vm.settings.currency,"type":"text","pattern":"[\\d\\.]*","inputmode":"decimal","hint":(_vm.operation.sell.price * _vm.operation.amount).toFixed(3) +
            ' ' +
            _vm.settings.currency +
            ' (Delta: ' +
            (
              _vm.operation.amount *
              (_vm.operation.sell.price - _vm.operation.buy.price)
            ).toFixed(2) +
            ')'},model:{value:(_vm.operation.sell.price),callback:function ($$v) {_vm.$set(_vm.operation.sell, "price", $$v)},expression:"operation.sell.price"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('my-date-picker',{attrs:{"label":"Sell Date"},model:{value:(_vm.operation.sell.date),callback:function ($$v) {_vm.$set(_vm.operation.sell, "date", $$v)},expression:"operation.sell.date"}})],1)],1),_c('v-select',{attrs:{"items":['open', 'closed'],"label":"state","persistent-hint":"","hint":_vm.operation.state == 'closed'
        ? 'Delta: ' +
          (
            _vm.operation.amount *
            (_vm.operation.sell.price - _vm.operation.buy.price)
          ).toFixed(2) +
          ' ' +
          _vm.settings.currency
        : ''},model:{value:(_vm.operation.state),callback:function ($$v) {_vm.$set(_vm.operation, "state", $$v)},expression:"operation.state"}}),_c('v-switch',{attrs:{"label":"Hide from calculations / totals"},model:{value:(_vm.operation.hide),callback:function ($$v) {_vm.$set(_vm.operation, "hide", $$v)},expression:"operation.hide"}}),_c('v-text-field',{attrs:{"name":"Comments","label":"Comments"},model:{value:(_vm.operation.comments),callback:function ($$v) {_vm.$set(_vm.operation, "comments", $$v)},expression:"operation.comments"}}),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"error","block":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")])],1),_c('v-col',[_c('v-btn',{attrs:{"color":"success","block":""},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save")])],1)],1),_c('br'),_c('span',{attrs:{"hidden":""}},[_vm._v(_vm._s(_vm.operation))]),_c('br'),_c('ad-sense-feed')],1)}
var staticRenderFns = []

export { render, staticRenderFns }